<template>
  <form @submit.prevent>
    <CourseType />
    <div class="row">
      <CustomSelect
        id="course-product-id"
        v-model:model-value="productId"
        label="В какой курс добавить этот тариф"
        :options="productOptions"
        tip="Выберите из существующих курсов"
        :errors="errors.product_ids"
        :search="(query) => store.dispatch('products/fetchProductsForSelect', query)"
        required
        class="mb-3 col-6"
      />
    </div>
    <div class="row">
      <div class="mb-3 col-3">
        <label for="course-stream-name" class="mb-2">Поток</label>
        <input
          id="course-stream-name"
          v-model.trim="course.stream_name"
          type="text"
          class="form-control"
          :class="errors.stream_name && 'is-invalid'"
          maxlength="10"
        />
        <span v-for="(error, index) in errors.stream_name" :key="index" class="invalid-feedback">
          {{ error }}
        </span>
        <small class="form-text text-muted">
          Например, Поток 3. Макс. 10 символов
        </small>
      </div>
      <div class="mb-3 col-3">
        <label for="course-tariff-name" class="mb-2">Тариф</label>
        <input
          id="course-tariff-name"
          v-model.trim="course.tariff_name"
          type="text"
          class="form-control"
          :class="errors.tariff_name && 'is-invalid'"
          maxlength="40"
        />
        <span v-for="(error, index) in errors.tariff_name" :key="index" class="invalid-feedback">
          {{ error }}
        </span>
        <small class="form-text text-muted">
          Например, Комфорт. Макс. 40 символов
        </small>
      </div>
    </div>
    <div class="row">
      <CourseUrl course-item-type="course" class="col-6 mb-3" />
    </div>
    <div class="row">
      <CustomSelect
        id="course-po-user-id"
        v-model:model-value="course.po_user_id"
        :options="poOptions"
        :errors="errors.po_user_id"
        label="Директор"
        tip="Список администраторов с заполненным redmine_user_id и ролью admin"
        class="mb-3 col-6"
        required
        :search="(query) => store.dispatch('course/fetchProductOwners', query)"
      />
    </div>
    <CourseDuration />
    <transition mode="out-in" name="fade">
      <div v-if="course.course_type === 'slurm'" class="row">
        <div class="mb-3 col-6">
          <label for="course-zoom-url" class="mb-2">Ссылка на zoom</label>
          <input
            id="course-zoom-url"
            v-model.trim="course.zoom_url"
            :class="errors.zoom_url && 'is-invalid'"
            type="text"
            class="form-control"
          />
          <span v-for="(error, index) in errors.zoom_url" :key="index" class="invalid-feedback">
            {{ error }}
          </span>
        </div>
      </div>
    </transition>
    <div class="row">
      <CustomSelect
        id="course-speaker-ids"
        v-model:model-value="course.speaker_ids"
        :options="speakersOptions"
        class="col-12 mb-3"
        multiple
        label="Спикеры"
      />
    </div>
    <div  class="mb-3">
      <label for="memo" class="mb-2">Памятка</label>
      <Editor id="memo" v-model:model-value="course.memo" />
    </div>
    <!-- Fast fix for change extended_description
    in tariffs types different from subscriptions and
    bundles. Need write tests and validations -->
    <div class="row">
      <div class="col-12 mb-3">
        <label for="extended-description" class="form-label">
          Расширенное описание (для продающей страницы)
        </label>
        <Editor
          id="extended-description"
          v-model:model-value="course.extended_description"
        />
      </div>
    </div>
  </form>
</template>

<script setup>
import CustomSelect from '@/components/CustomSelect';
import CourseDuration from '@/components/courses/CourseDuration';
import CourseType from '@/components/courses/CourseType';
import CourseUrl from '@/components/courses/CourseUrl';

import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { useCustomSelectOptions } from '@/composables/use_custom_select_options';

const store = useStore();

const route = useRoute();

const course = computed(() => store.getters['course/getCourse']);

if (route.query.product && !isNaN(route.query.product)) {
  course.value.product_ids = [Number(route.query.product)];
}

const errors = computed(() => store.getters['course/getErrors']);

const poOptions = computed(() => {
  const productOwners = store.getters['course/getProductOwners'];
  return productOwners
    ? productOwners.map((po) => ({ value: po.id, name: po.full_name || po.email }))
    : null;
});
const products = computed(() => store.getters['products/getProducts']);

const productOptions = computed(() => useCustomSelectOptions(products.value, 'id', 'name'));

const productId = ref(course.value.product_ids ? course.value.product_ids[0] : null);

watch(() => course.value.product_ids, () => {
  productId.value = course.value.product_ids ? course.value.product_ids[0] : null;
});

watch(productId, () => {
  course.value.product_ids = productId.value ? [productId.value] : null;
});

const speakers = computed(() => store.getters['speakers/getSpeakers']);

const speakersOptions = computed(() => useCustomSelectOptions(speakers.value, 'id', 'full_name'));
</script>
