import validateCourseTypes from './helpers/course/validate_course_types';
import validateProductDescription from './helpers/product/validate_product_description';
import validateProductExternalUrl from './helpers/product/validate_product_external_url';
import validateNotEmptyField from './helpers/validate_not_empty_field';

export default function validateContainer(value) {
  const errors = {};

  if (!value) {
    errors.container = [];
    if (!value) errors.container.push('Курс не может быть пустым');
    return errors;
  }

  const displayedType = validateCourseTypes(value.displayed_type, value.course_type);
  if (displayedType.length) errors.displayed_type = displayedType;

  const linkedCoursesIds = validateNotEmptyField(value.linked_courses_ids);
  if (linkedCoursesIds.length) errors.linked_courses_ids = linkedCoursesIds;

  const poUserId = validateNotEmptyField(value.po_user_id);
  if (poUserId.length) errors.po_user_id = poUserId;

  const expireDays = validateNotEmptyField(value.expire_days);
  if (expireDays.length) errors.expire_days = expireDays;

  const price = validateNotEmptyField(value.price);
  if (price.length) errors.price = price;

  if (value.course_type === 'subscription') {
    const externalUrl = validateProductExternalUrl(value.external_url, {
      publicationStatus: value.publication_status,
    });
    if (externalUrl.length) errors.external_url = externalUrl;
  }

  const description = validateProductDescription(value.description);
  if (description.length) errors.description = description;

  const directionIds = validateNotEmptyField(value.direction_ids);
  if (directionIds.length) errors.direction_ids = directionIds;

  return errors;
}
